<template>
    <div class="patientRecharge">
        <div v-if="!curSn">
            <div class="instrumentList">
                <div class="instrumentCard">
                    <div class="carBody">
                        <div class="noInstrument">暂无治疗仪</div>
                    </div>
                </div>
                <div class="patientInfo">
                    <div class="userInfo">
                        <van-cell-group inset>
                            <van-cell center :icon="userDefault" title="未绑定用户" label=" ">
                                <template #right-icon>
                                    <van-button
                                        round
                                        size="mini"
                                        plain
                                        color="#4AB8AB"
                                        icon="play"
                                        type="info"
                                        @click="toMyEquipmentsPage"
                                        >去绑定
                                    </van-button>
                                </template>
                            </van-cell>
                        </van-cell-group>
                        <van-row class="course">
                            <van-col span="8">
                                <div class="courseTitle">预计下次验配</div>
                                <div class="courseInfo">-</div>
                            </van-col>
                            <van-col span="8">
                                <div class="courseTitle">可用疗程验配</div>
                                <div class="courseInfo">-</div>
                            </van-col>
                            <van-col span="8">
                                <div class="courseTitle">可用疗程期</div>
                                <div class="courseInfo">-</div>
                            </van-col>
                        </van-row>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="curSn">
            <div class="instrumentList">
                <div class="instrumentCard">
                    <div class="cardTile cell myBound">
                        <div class="cardTileLeft">我的治疗仪</div>
                    </div>
                    <div class="cardBody">
                        <div class="cell">
                            <div class="cardBodyLeft">ID</div>
                            <div class="cardBodyRight cellFlex-1">{{ curSn }}</div>
                        </div>
                    </div>
                </div>
                <div class="patientInfo">
                    <div class="userInfo">
                        <van-cell-group inset>
                            <van-cell center>
                                <template #icon>
                                    <van-image
                                        :src="curSnUser.patientWxHeadimgurl || userPhoto"
                                        width="35"
                                    />
                                </template>
                                <template #title>
                                    <span class="userName mlr10">{{ curSnUser.patientName }}</span>
                                    <van-icon
                                        :name="curSnUser.patientSex === 1 ? sexNan : sexNv"
                                        class="sex-icon"
                                    />
                                </template>
                                <template
                                    #right-icon
                                    v-if="
                                        latestOrder &&
                                        latestOrder.sn === curSn &&
                                        latestOrder.status === 10
                                    "
                                >
                                    <van-button
                                        class="orderButton"
                                        round
                                        size="mini"
                                        plain
                                        color="#D0021B"
                                        type="info"
                                        @click="toMyRechargeRecordsPage()"
                                        >有待支付订单</van-button
                                    >
                                </template>
                            </van-cell>
                        </van-cell-group>
                        <van-row class="course">
                            <van-col span="8">
                                <div class="courseTitle">预计下次验配</div>
                                <div class="courseInfo">2022-01-18</div>
                            </van-col>
                            <van-col span="8">
                                <div class="courseTitle">可用疗程验配</div>
                                <div class="courseInfo">{{ curSnUser.treatmentCnt }}个</div>
                            </van-col>
                            <van-col span="8">
                                <div class="courseTitle">可用疗程期</div>
                                <div class="courseInfo">2022-03-31</div>
                            </van-col>
                        </van-row>
                    </div>
                    <div class="packageShow" v-if="goodsList.length">
                        <div class="packageTitle">选择疗程</div>
                        <van-row class="packageBody" gutter="15">
                            <van-col span="8" @click="createOrder(goodsList[0].id)">
                                <div class="packageCard">
                                    <div class="mainText">
                                        {{ goodsList[0].treatmentCnt }}个疗程验配
                                    </div>
                                    <div class="minorText">
                                        <span>14天/疗程 </span
                                        ><span>共{{ goodsList[0].treatmentDayCnt }}天</span>
                                    </div>
                                    <div class="nowPrice" style="margin-top: 56px">
                                        <span>￥</span> {{ goodsList[0].salePrice / 100 }}
                                    </div>
                                </div>
                            </van-col>
                            <van-col span="8" @click="createOrder(goodsList[1].id)">
                                <div class="packageCard">
                                    <div class="mainText">
                                        {{ goodsList[1].treatmentCnt }}个疗程验配
                                    </div>
                                    <div class="minorText">
                                        <span>14天/疗程 </span
                                        ><span>共{{ goodsList[1].treatmentDayCnt }}天</span>
                                    </div>
                                    <div class="oldPrice">
                                        <span>￥</span> {{ goodsList[1].price / 100 }}
                                    </div>
                                    <div class="nowPrice">
                                        <span>￥</span> {{ goodsList[1].salePrice / 100 }}
                                    </div>
                                    <div class="saleTag">约7.0折</div>
                                </div>
                            </van-col>
                            <van-col span="8" @click="createOrder(goodsList[2].id)">
                                <div class="packageCard">
                                    <div class="mainText">
                                        {{ goodsList[2].treatmentCnt }}个疗程验配
                                    </div>
                                    <div class="minorText">
                                        <span>14天/疗程 </span
                                        ><span>共{{ goodsList[2].treatmentDayCnt }}天</span>
                                    </div>
                                    <div class="oldPrice">
                                        <span>￥</span> {{ goodsList[2].price / 100 }}
                                    </div>
                                    <div class="nowPrice">
                                        <span>￥</span> {{ goodsList[2].salePrice / 100 }}
                                    </div>
                                    <div class="saleTag">约4.6折</div>
                                </div>
                            </van-col>
                        </van-row>
                    </div>
                    <!-- <van-radio-group>
                        <van-radio use-icon-slot class="textC">
                            <van-icon class="chosenIcon" name="passed" size="16" color="#4AB8AB" />
                            我已阅读并同意 <span>《客户须知》</span>
                            <image slot="icon" class="" src="../../../assets/images/radio.png" />
                        </van-radio>
                    </van-radio-group> -->
                    <van-checkbox class="checkBox mtb10" v-model="checked" icon-size="16px"
                        >我已阅读并同意<span class="customersInformation" @click="showPopup"
                            >《客户须知》</span
                        ></van-checkbox
                    >
                    <van-popup
                        v-model="show"
                        closeable
                        close-icon="close"
                        position="bottom"
                        :style="{ height: '100%', background: 'linear-gradient(#daf6f0, #fff)' }"
                    >
                        <div class="popupTitle">客户须知</div>
                        <div class="popupText">
                            <p>感谢您选择耳宁牌耳鸣治疗仪！</p>
                            <p>
                                耳鸣的主观性导致患者个体差异性较大，需要根据患者病情制定阶段性的治疗目标。疗程服务结束后，设备会锁定，患者将不能使用治疗仪，请联系专业人员进行效果评估、听力检测和设备调试。仪器所配基础疗程结束后请购买新的疗程服务，并制定新的治疗计划。
                            </p>
                            <p>
                                本产品属国家正规二类医疗器械，治疗处方音需根据患者病情制定，非产品本身质量问题不予退换。产品使用及售后服务详见《使用说明书》。
                            </p>
                            <p>
                                患者购买的“验配服务”属于个性化定制服务，在疗程期内可享受专业的康复指导、复测、验配等服务，该服务内容不得转让、退换或退现。请在购买时咨询相关工作人员，并酌情购买。
                            </p>
                            <p>
                                自购买本产品之日起即获得无锡矽太益耳听力科技有限公司“悦听悦心”健康协会终身会员资格。免费获得“悦听悦心”健康协会安排的康复讲座、沙龙、患者交流会等会员服务。
                            </p>
                        </div>
                    </van-popup>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { refreshCurSnUser, toast } from '@/util';
import { getGoodsList } from '@/services/goods';
import { createWxPrepay, getPaymentByPaymentNo } from '@/services/payment';
import { createOrder, getLatestOrder } from '@/services/order';
import { WX_APPID } from '../../../config';

export default {
    // name: "index"
    data() {
        return {
            goodsList: [],
            curSn: '',
            curSnUser: {},
            latestOrder: {},

            userPhoto: require('@/assets/images/userPhoto.png'),
            userDefault: require('@/assets/images/userDefault.png'),
            sexNan: require('@/assets/images/nan.png'),
            sexNv: require('@/assets/images/nv.png'),
            show: false,
            checked: true,
        };
    },
    created() {
        this.curSn = localStorage.getItem('curSn');
        if (this.curSn) {
            this.curSnUser = JSON.parse(localStorage.getItem('curSnUser'));
        }
        this.getGoodsList();
        this.getLatestOrder();
    },
    methods: {
        async getGoodsList() {
            const { code, message, data } = await getGoodsList();
            if (!code) {
                return toast(message);
            }
            this.goodsList = data;
        },

        async getLatestOrder() {
            const { code, message, data } = await getLatestOrder();
            if (!code) {
                return toast(message);
            }
            this.latestOrder = data;
        },

        toMyEquipmentsPage() {
            this.$router.push('/patient/my/equipments');
        },

        async createOrder(goodsId) {
            if (!this.checked) {
                return toast('请先勾选同意《客户须知》');
            }
            const { code, message, data } = await createOrder({ sn: this.curSn, goodsId });
            if (!code) {
                return toast(message);
            }
            await this.getLatestOrder();
            await this.createWxPrepay(data);
        },

        async toMyRechargeRecordsPage() {
            await this.$router.push('/patient/my/rechargeRecords');
        },

        async createWxPrepay(orderId) {
            const { code, message, data } = await createWxPrepay({ orderId });
            if (!code) {
                return toast(message);
            }
            const { payment, bridgeConfig } = data;
            this.onBridgeReady(payment, {
                ...bridgeConfig,
                appId: WX_APPID,
                timeStamp: bridgeConfig.timestamp,
            });
        },
        onBridgeReady(payment, data) {
            // eslint-disable-next-line no-undef
            WeixinJSBridge.invoke('getBrandWCPayRequest', data, async function (res) {
                if (res.err_msg === 'get_brand_wcpay_request:ok') {
                    const { code, message, data } = await getPaymentByPaymentNo(payment.paymentNo);
                    if (!code) {
                        return toast(message);
                    }
                    if (data && data.status === 2) {
                        toast('支付成功');
                        await refreshCurSnUser();
                        return;
                    }
                    return toast('支付失败');
                } else {
                    toast('取消支付');
                }
            });
        },
        showPopup() {
            this.show = true;
        },
    },
};
</script>

<style scoped>
.instrumentList {
    /* padding: 15px 0; */
    padding: 10px;
}

.instrumentCard {
    background: #fff;
    overflow: hidden;
    border-radius: 8px;
}

.cardTile {
    box-sizing: border-box;
    padding: 10px 16px;
    overflow: hidden;
    color: #323233;
    font-size: 14px;
    line-height: 24px;
    position: relative;
}

.cardTile::after {
    content: '';
    display: block;
    position: absolute;
    height: 1px;
    background: #4ab8ab;
    width: 90%;
    left: 5%;
    bottom: 0;
}

.myBound.cardTile::after {
    background: #bebebe;
}

.erNing::before {
    content: 'ERNING';
    display: block;
    position: absolute;
    color: #acacac;
    font-size: 18px;
    top: 2px;
    left: 21px;
}

.cardTileLeft {
    position: relative;
}

.cardTileLeft::before {
    content: '';
    display: block;
    position: absolute;
    left: -16px;
    top: 3px;
    width: 5px;
    height: 20px;
    background: #4ab8ab;
}

.cardTileRight {
    font-size: 14px;
    font-weight: 600;
    color: #4ab8ab;
}

.cardBody .cardBodyLeft {
    font-size: 24px;
    color: #8d8d8d;
    font-weight: 600;
}

.cardBody .cardBodyRight {
    font-size: 24px;
    color: #282a2d;
    text-align: center;
}

.noInstrument {
    font-size: 12px;
    padding: 15px 16px;
    color: #8d8d8d;
    font-weight: 600;
}

.patientInfo {
    background: #fff;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    overflow: hidden;
    margin-top: 10px;
    padding: 10px;
}

.userInfo .van-cell__left-icon {
    font-size: 35px;
    height: auto;
    line-height: 100%;
}

.userName {
    font-size: 16px;
    color: #282a2d;
}
.sex-icon {
    font-size: 16px;
    vertical-align: middle;
}

.userInfo .van-cell-group {
    background: #f7f7f7;
    margin: 0;
}

.userInfo .van-cell {
    background: none;
}

.course {
    text-align: center;
    line-height: 24px;
    color: #282a2d;
    padding: 10px 0;
}

.course .van-col {
    border-right: 1px solid #707070;
}

.course .van-col:last-child {
    border-right: none;
}

.courseTitle {
    font-size: 12px;
}

.courseInfo {
    font-size: 16px;
    font-weight: 600;
}

.packageShow {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background: linear-gradient(#daf6f0, #fff);
    padding: 16px 15px;
}

.packageTitle {
    position: relative;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    line-height: 24px;
    padding-bottom: 16px;
}

.packageTitle::before,
.packageTitle::after {
    display: block;
    content: '';
    position: absolute;
    height: 1px;
    width: 15px;
    background: #979797;
    left: 50%;
    top: 10px;
}

.packageTitle::before {
    margin-left: -45px;
}

.packageTitle::after {
    margin-left: 28px;
}

.packageCard {
    border-radius: 5px;
    padding: 15px 0;
    background: #fff;
    text-align: center;
    position: relative;
    overflow: hidden;
}

.packageCard::before {
    position: absolute;
    display: block;
    content: '';
    height: 5px;
    width: 100%;
    top: 0;
    left: 0;
}

.packageBody .van-col:first-child .packageCard::before {
    background: #e5e5e5;
}

.packageBody .van-col:nth-child(2) .packageCard::before {
    background: #5cc77f;
}

.packageBody .van-col:nth-child(3) .packageCard::before {
    background: #4e72fe;
}

.mainText {
    font-size: 14px;
    color: #282a2d;
    margin-top: 20px;
    font-weight: bold;
}

.minorText {
    font-size: 10px;
    color: #868686;
    margin-top: 20px;
}

.minorText span {
    display: block;
}

.oldPrice {
    margin-top: 35px;
    font-size: 9px;
    color: #c8c8c8;
    text-decoration: line-through;
}

.nowPrice {
    margin-top: 5px;
    font-size: 15px;
    color: #4e4e4e;
    font-weight: bold;
}

.nowPrice span {
    font-size: 12px;
}

.saleTag {
    font-size: 9px;
    color: #fff;
    background: linear-gradient(-90deg, #edcb83, #fdd99e);
    position: absolute;
    top: 10px;
    left: -25px;
    transform: rotate(-35deg);
    line-height: 15px;
    height: 15px;
    width: 100px;
}
.orderButton {
    line-height: 1;
    font-weight: bold;
}
.checkBox {
    text-align: center;
    justify-content: center;
}
.popupTitle {
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    color: #333;
    padding: 40px 0 5px 0;
}
.popupText {
    padding: 0 30px;
    font-size: 12px;
    color: #666;
    line-height: 24px;
    height: calc(100% - 64px);
    overflow-y: auto;
}
.popupText p {
    text-indent: 15px;
}
.customersInformation {
    color: #4ab8ab;
}
</style>
